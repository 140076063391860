<template>

<el-card class="box-card">
  <template #header>
    <div class="card-header" >
      <span style="float: left">正式版</span>
<!--<router-link to="/chat" style="float: right;color: #000000;text-decoration: none;">ChatGPT</router-link>-->
<!--      <a href="https://hhao.haunghao.top/shared.html" style="float: right;color: #000000;text-decoration: none;">ChatGPT</a>-->
<!--      <a  style="float: right;color: #000000;text-decoration: none;"></a>-->
      <br/>
<!--      <el-button class="button" type="text">操作按钮</el-button>-->
    </div>
  </template>
  <div  class="text item">
    <el-form
  :model="ruleForm"
  status-icon
  :rules="rules"
  ref="ruleForm"
  label-width="100px"
  class="demo-ruleForm"
>
  <el-form-item label="学号" prop="pass">
    <el-input
      type="text"
      v-model="ruleForm.pass"
      autocomplete="off"
    ></el-input>
<!--      {{ruleForm.pass}}-->
  </el-form-item>
  <el-form-item label="密码" prop="checkPass">
    <el-input
      type="password"
      v-model="ruleForm.checkPass"
      autocomplete="off"
    ></el-input>
  </el-form-item>
<!--  <el-form-item label="cookie" prop="age">-->
<!--    <el-input v-model.number="ruleForm.age"></el-input>-->
<!--  </el-form-item>-->
  <el-form-item>
    <el-button type="primary" @click="submitForm('ruleForm')">提交</el-button>
    <el-button @click="resetForm('ruleForm')">重置</el-button>
  </el-form-item>
</el-form>
  </div>
</el-card>


</template>


<script>
  import service from "../until/request";
  export default {
    data() {
      return {
        ruleForm: {
          pass: '',
          checkPass: '',
          age: '',
        },
        rules: {
          // pass: [{ validator: validatePass, trigger: 'blur' }],
          // checkPass: [{ validator: validatePass2, trigger: 'blur' }],
          // age: [{ validator: checkAge, trigger: 'blur' }],
        },
      }
    },
    methods: {
         handleSelect(key, keyPath) {
        console.log(key, keyPath)
      },
      async submitForm(formName) {
             console.log(formName)

            // console.log(formName.checkPass)
        this.$refs[formName].validate((valid) => {

          if (valid) {
              console.log(valid)
              service({
                url:'/logins/',
                method:'post',
                data: {
                  username: this.ruleForm.pass,
                password: this.ruleForm.checkPass
                }
              }).then((res)=>{
                  // alert(res.data.user)
                console.log(res)
                if (res.data.token){
                  const token= res.data.token
                  this.$store.commit('setToken',  token)
                  console.log(this.$store.state.token)

                }
                this.$router.push('/about')
              }).catch(reason => {
                console.log(reason)
                console.log('err')
              })
          } else {
            console.log('error submit!!')
            return false
          }

        })

      },
      resetForm(formName) {
           // service({
           //      url:'/tests/',
           //      method:'get'
           //    }).then((res)=>{
           //        console.log(res)
           //    });
      //      const res = service('/getinfo/')
      // console.log(res)
        this.$refs[formName].resetFields()
      },
    },
  }
</script>


<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /*text-align: center;*/
  color: #2c3e50;
}


/*nav a {*/
/*  font-weight: bold;*/
/*  color: #2c3e50;*/
/*}*/





  .el-main {
    background-color: #e9eef3;
    color: var(--el-text-color-primary);
    text-align: center;
    line-height: 160px;
  }

  /*body > .el-container {*/
  /*  margin-bottom: 40px;*/
  /*}*/

  /*.el-container:nth-child(5) .el-aside,
  .el-container:nth-child(6) .el-aside {
    line-height: 260px;
  }

  .el-container:nth-child(7) .el-aside {
    line-height: 320px;
  }*/
</style>
