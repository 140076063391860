import { createRouter, createWebHashHistory, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'


const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    meta: { requiresAuth: true },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: HomeView
  },
    {
    path: '/video',
    name: 'video',
    meta: { requiresAuth: true },
    component: () => import('../views/video.vue')
  },{
    path: '/videos',
    name: 'videos',
    meta: { requiresAuth: true },
    component: () => import('../views/videos.vue')
  },{
    path: '/chat',
    name: 'chat',
    // meta: { requiresAuth: true },
    component: () => import('../views/chat.vue')
  },{
    path: '/file',
    name: 'file',
    // meta: { requiresAuth: true },
    component: () => import('../views/filemanage.vue')
  },
    {
    path: '/getcode',
    name: 'getcode',
    // meta: { requiresAuth: true },
    component: () => import('../views/getcode.vue')
  },
  //   {
  //   path: '/about',
  //   name: 'about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is la    zy-loaded when the route is visited.
  //   component: home
  // },

]
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})
// const router = createRouter({
//   history: createWebHistory(),
//   routes
// })
router.beforeEach((to, from, next) => {
  // if (localStorage.getItem('token')){
  //   if (to.path == '/login' || to.path == '/'){
  //     next('/about')
  //   }else {
  //     next()
  //   }
  // }
  if (to.meta.requiresAuth) {
    // 需要登录验证
    if (!localStorage.getItem('token')) {
      // 未登录，跳转到登录页面
      next('/login');
    } else {
      // 已登录，继续访问页面
      next();
    }
  } else {
    // 不需要登录验证，直接访问页面
    next();
  }
});
// router.beforeEach((to, from, next) => {
//   if (to.matched.some(r => r.meta.requireAuth)) {
//     if (store.state.token) {next()}
//     else {
//       next({
//         path: '/login',
//         query: {
//           redirect: to.fullPath
//         }})}}
//   else {next()}
// })

export default router

