import { createStore } from 'vuex'

export default createStore({
  state: {
    token: '',
    path: ''

  },
  getters: {
  },
  mutations: {
    setToken(state, token) {
      state.token = token;
      localStorage.setItem('token', token);
    },
    removeToken(state) {
      state.token = '';
      localStorage.removeItem('token');
    },
    removePath(state) {
      state.token = '';
      localStorage.removeItem('path');
    },
    setPath(state, path) {
      state.path = path;
      localStorage.setItem('path', path);
    },
  },
  actions: {
  },
  modules: {
  }
})
