import axios from 'axios'
import store from '../store'
import router from '../router'
// 创建axios 自己改请求前缀
// const service = axios.create({
//   baseURL: 'http://127.0.0.1:8000'
// });
const service = axios.create({
  baseURL: 'https://qlj.haunghao.com'
});
// service.defaults.headers.common['authorization'] = store.state.token

// service.interceptors.request.use(config => {    'https://qlj.haunghao.top'
//   if (this.$store.state.token) {
//     config.headers.common['authorization'] = this.$store.state.token
//   }
//   return config
// },
// error => {
//   return Promise.reject(error)
// })

// service.interceptors.response.use(response =>
//     {return response},error =>
//     {if (error.response) {
//       switch (error.response.status) {
//         case 401:this.$store.commit('removeToken')
//         this.$router.replace({path: '/login',query: {
//           redirect: this.$router.currentRoute.fullPath
//         }
//         }
//         )
//       }
//     }
//     return Promise.reject(error.response.data)}
// )


// 添加请求拦截器
service.interceptors.request.use(function (config) {
  // 在发送请求之前做些什么
  // if (store.state.token) {
  //   config.headers['authorization'] = 'Bearer '+ store.state.token
  // }
  // console.log(store.state.token)
  // let token = window.localStorage.getItem('token')
  // if (token){
  //   config.headers['authorization'] = token
  // }
  const token = localStorage.getItem('token');
  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`;
  }
  // console.log(config)
  return config
  // return config;
}, function (error) {
  // 对请求错误做些什么
  return Promise.reject(error);
});

// 添加响应拦截器
service.interceptors.response.use(function (response) {
  // 对响应数据做点什么
  // console.log('res',response);
  return response;
},
    function (error) {
  // 对响应错误做点什么
  // console.log(store.state.token)
  console.log(error)
  if (error.response.status === 401) {
    // console.log(error)
    alert('请重新登录')
    store.commit('removeToken')
    store.commit('removePath')
    router.replace({path: '/login',query: {
      redirect: router.currentRoute.fullPath
    }
    }
    )
  }else if (error.response.status === 400){
    console.log(error.response.data.non_field_errors)
    alert(error.response.data.non_field_errors)
  }
  else if (error.response.status === 504){
    alert("504 Gateway Timeout")
  }
  else {
    // console.log(error)
  }
  // alert('请重新登录')

  // return Promise.reject(error);
});

export default service;

