<template>
<router-view></router-view>

    <el-button size="large" class="fixed-button" type="info" @click="centerDialogVisible = true" circle><el-icon>
  <message />
</el-icon>
      </el-button>


    <el-dialog :width="dialogWidth"
  title="留言"
  v-model="centerDialogVisible"
  destroy-on-close
  center
>
<!--  <span>需要注意在 Dialog 打开前是这条内容和下面的内容都是不会被渲染的</span>-->
<!--  <strong>额外的内容</strong>-->
<el-input
  type="textarea"
  placeholder="请输入内容"
  v-model="textarea"
  maxlength="50"
  show-word-limit
>
</el-input>


  <template #footer>
    <span class="dialog-footer">
      <el-button @click="centerDialogVisible = false">取 消</el-button>
      <el-button type="primary" @click="comfirm"
        >确 定</el-button
      >
    </span>
  </template>
</el-dialog>
</template>



<script setup>
    // app.vue写在script里面  main.js写在app挂在完之后
const debounce = (fn, delay) => {
  let timer
   return (...args) => {
     if (timer) {
       clearTimeout(timer)
     }
     timer = setTimeout(() => {
       fn(...args)
     }, delay)
   }
}

const _ResizeObserver = window.ResizeObserver;
window.ResizeObserver = class ResizeObserver extends _ResizeObserver{
   constructor(callback) {
     callback = debounce(callback, 200);
     super(callback);
   }
}

    import {Message} from '@element-plus/icons'
    import {ref, onMounted} from 'vue'
    import service from "./until/request";
    import {ElMessage} from "element-plus";
    let centerDialogVisible = ref(false);
    let textarea = ref('')
    const dialogWidth = ref('30%');
    // 监听窗口大小变化，设置对话框的宽度
    function comfirm() {
        service.post('/ly/', {
            liuyan: textarea.value
        }).then((res) => {
            console.log(res)
        })
        centerDialogVisible.value = false
        ElMessage('发送成功')
    }
    const updateDialogWidth = () => {
      const windowWidth = window.innerWidth;
      if (windowWidth < 600) {
        dialogWidth.value = '90%';
      } else if (windowWidth < 960) {
        dialogWidth.value = '70%';
      } else {
        dialogWidth.value = '30%';
      }
    };
    onMounted(() => {
      window.addEventListener('resize', updateDialogWidth);
      updateDialogWidth(); // 初始化宽度
    });
</script>


<style>
    .fixed-button {
        font-size: 20px;
  position: fixed;
  bottom: 20px;
  right: 20px;
  /* 其他样式 */
}
</style>
