import { createApp } from 'vue'
import ElementPlus from 'element-plus'
// import 'bootstrap-icons'
// import VuePlyr from 'vue-plyr'
// import 'vue-plyr/dist/vue-plyr.css'
import hls from "videojs-contrib-hls";
import VueVideoPlayer from '@videojs-player/vue'
import 'video.js/dist/video-js.css'
import 'element-plus/dist/index.css'
import zhCn from 'element-plus/es/locale/lang/zh-cn'
import App from './App.vue'
import router from './router'
import axios from "axios";
import store from './store'
// createApp(App).mount('#app')
const app = createApp(App).use(store).use(router)
// import vue3videoPlay from 'vue3-video-play' // 引入组件
// import vue3videoPlay from 'vue3-video-play-emiyagm'
// import 'vue3-video-play/dist/style.css' // 引入css
// app.use(vue3videoPlay)
// axios.defaults.baseURL = '127.0.0.1:8080'
app.config.globalProperties.$http = axios
app.use(ElementPlus, {
    locale: zhCn,
})
app.use(hls)
app.use(VueVideoPlayer)
// app.use(VuePlyr, {
//     plyr: {}
//   })
app.mount('#app')
// app.config.globalProperties.$axios=axios;
